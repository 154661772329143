import { FC } from "react";

interface IProps {
  title: string;
  content: string[];
  isListed: boolean;
}

export const CatalogDetailedArticle: FC<IProps> = ({
  title,
  content,
  isListed,
}: IProps) => {
  return (
    <article className="catalog-article">
      <h3 className="catalog-article__title">{title}</h3>
      {isListed ? (
        <ul className="catalog-article__list">
          {content
            ? content.map((contentpart, i) => (
                <li className="catalog-article__list-item" key={i}>
                  - {contentpart}
                </li>
              ))
            : null}
        </ul>
      ) : (
        <p className="catalog-article__paragraph">{content}</p>
      )}
    </article>
  );
};
