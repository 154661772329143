import React, { useEffect, useState, lazy, Suspense } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

//Import Main Page Components
import { Header } from "./components/Header";
// import { Index } from "./pages/Index";
import { Catalog } from "./pages/Catalog";
// import { About } from "./pages/About";
// import { Contact } from "./pages/Contact";
import { Privacy } from "./pages/Privacy";
import { Footer } from "./components/Footer";

import { NotFound } from "./pages/NotFound";
//Import Hooks
import ScrollToTop from "./utils/ScrollToTop";
//Animation
import { AnimatePresence } from "framer-motion";

// const Catalog = lazy(() => import("./pages/Catalog"));
const Contact = lazy(async () => await import("./pages/Contact"));
const Index = lazy(async () => await import("./pages/Index"));
const About = lazy(async () => await import("./pages/About"));

function App() {
  const location = useLocation();

  //Header Hamburger Y Calculator State
  const [scrollState, setScrollState] = useState(0);

  //Header Hamburger Y Calculator Effect
  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setScrollState(winScroll);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
  }, []);
  return (
    <div className="App">
      {/* Header - doesn't change with URL */}
      <Header scrollState={scrollState} />
      <ScrollToTop />
      <AnimatePresence exitBeforeEnter>
        {/* Main Content - changing with URL */}
        <Switch location={location} key={location.pathname}>
          {/* Index Page */}
          <Route path="/" exact>
            <Suspense fallback={<div>Loading...</div>}>
              <Index />
            </Suspense>
          </Route>
          {/* Catalog Page */}
          <Route exact path="/catalog">
            {localStorage.getItem("lastId") == null ? (
              <Redirect to="/catalog/1" />
            ) : (
              <Redirect to={`/catalog/${localStorage.getItem("lastId")}`} />
            )}
          </Route>
          <Route path="/catalog/:id" component={Catalog}></Route>
          {/* About Page */}
          <Route path="/about" exact>
            <Suspense fallback={<div>Loading...</div>}>
              <About />
            </Suspense>
          </Route>
          {/* Contact Page */}
          <Route path="/contact" exact>
            <Suspense fallback={<div>Loading...</div>}>
              <Contact />
            </Suspense>
          </Route>
          <Route path="/privacy" exact>
            <Privacy />
          </Route>
          <Route path="*" component={NotFound} />
        </Switch>
      </AnimatePresence>
      {/* Footer - doesn't change with URL */}
      <Footer />
    </div>
  );
}

export default App;
