import { Link } from "react-router-dom";
import { FC } from "react";

interface IProps {
  buttonText: string;
  isLink: boolean;
  clickEvent: () => void;
  isPrimary: boolean;
  isDisabled?: boolean;
  linkPath: string;
  isSubmit?: boolean;
}

export const Button: FC<IProps> = ({
  buttonText,
  isLink,
  isPrimary,
  linkPath,
  isDisabled = false,
  clickEvent,
  isSubmit = false,
}: IProps) => {
  return (
    <>
      {isLink ? (
        <Link
          className={
            isPrimary
              ? "button--default button--primary button--default__text button--primary__text"
              : "button--default button--secondary button--default__text button--secondary__text"
          }
          onClick={() => clickEvent()}
          to={linkPath}
        >
          {buttonText}
        </Link>
      ) : (
        <button
          type={isSubmit ? "submit" : "button"}
          disabled={isDisabled}
          onClick={() => clickEvent()}
          className={
            isPrimary
              ? "button--default button--primary button--default__text button--primary__text"
              : "button--default button--secondary button--default__text button--secondary__text"
          }
        >
          {buttonText}
        </button>
      )}
    </>
  );
};
