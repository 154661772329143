import { FC } from "react";
import { Link } from "react-router-dom";

interface IProps {
  path: string;
  text: string;
}

export const FooterLink: FC<IProps> = ({ path, text }: IProps) => {
  return (
    <Link className="footer-link" to={path}>
      <p className="footer-link__text">{text}</p>
      <div className="footer-link__underline"></div>
    </Link>
  );
};
