export const cacheImages = async (srcArray: string[]) => {
  const promises = await srcArray.map((src: string) => {
    // console.log(src);
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve(null) as any;
      img.onerror = reject() as any;
    });
  });

  await Promise.all(promises);
};
