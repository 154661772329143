export const fetchCatalog = (): Promise<CatalogItem[]> => {
  return fetch("./catalog.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((rawCatalog) => {
    return rawCatalog.json();
  });
};

export const getCategories = (items: CatalogItem[]): string[] => {
  let categories: string[] = ["Minden termék"];

  items.forEach((item) => {
    item.categories.forEach((category) => {
      if (!categories.includes(category)) categories.push(category);
    });
  });

  return categories;
};

export const getRandom = (arr: CatalogItem[], n: number) => {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    if (
      arr[x in taken ? taken[x] : x].pictures[0].url !==
      "/termekek/placeholder.jpg"
    ) {
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    } else n++;
  }
  return result;
};
