//FacebookLink for IndexHero and Footer
export const FacebookLink = () => {
  return (
    <a
      href="https://www.facebook.com/miszterhussamson"
      rel="noreferrer"
      target="_blank"
      referrerPolicy="no-referrer"
      aria-label="Facebook"
    >
      <svg
        className="facebook-link"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <circle
            cx="16"
            cy="16"
            r="15"
            fill="white"
            className="facebook-link__back"
          />
          <path
            d="M16 0C7.16342 0 0 7.16342 0 16C0 24.8366 7.16342 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16342 24.8366 0 16 0ZM22.1041 8.14813L19.8911 8.14911C18.1557 8.14911 17.8202 8.97375 17.8202 10.1837V12.8517H21.9584L21.9564 17.0305H17.8205V27.7544H13.5042V17.0305H9.89594V12.8517H13.5042V9.7699C13.5042 6.19343 15.6893 4.24561 18.8795 4.24561L22.1044 4.25052V8.14813H22.1041Z"
            fill="#999999"
            className="facebook-link__front"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
};
