import { FC, useState } from "react";
import { CatalogDetailedArticle } from "../catalog/CatalogDetailedArticle";
import { Button } from "../Button";
import { CatalogArrow } from "./CatalogArrow";
import { CatalogList } from "./CatalogList";
import { FullPictureOverlay } from "../FullPictureOverlay";

interface IProps {
  catalog: CatalogItem[];
  active: number;
  item: CatalogItem;
  setActiveItem: (active: number) => void;
}

export const CatalogDetailedItem: FC<IProps> = ({
  catalog,
  active,
  item,
  setActiveItem,
}: IProps) => {
  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  const [isPictureOpen, setIsPictureOpen] = useState(false);

  return (
    <section className="catalog-detailed">
      {isCatalogOpen ? (
        <CatalogList
          isMobile={true}
          setIsOpen={setIsCatalogOpen}
          catalog={catalog}
          active={active}
          setActiveItem={setActiveItem}
        />
      ) : isPictureOpen ? (
        <FullPictureOverlay setIsOpen={setIsPictureOpen} item={item} />
      ) : (
        <>
          <div className="catalog-detailed__menu">
            <CatalogArrow
              arrowText="Előző termék"
              isLeft={true}
              clickEvent={() => setActiveItem(item.id - 1)}
            />
            <div className="catalog-detailed__catalog-button-container">
              <Button
                buttonText="Katalógus"
                isLink={false}
                isPrimary={false}
                linkPath=""
                clickEvent={() => setIsCatalogOpen(true)}
              />
            </div>
            <CatalogArrow
              arrowText="Következő termék"
              isLeft={false}
              clickEvent={() => setActiveItem(item.id + 1)}
            />
          </div>
          <div
            className="catalog-detailed__picture-container"
            onClick={() => {
              setIsPictureOpen(true);
            }}
          >
            {/* <LazyLoad once> */}
            <img
              className="catalog-detailed__picture"
              src={item ? item.pictures[0].url : ""}
              alt={item ? item.pictures[0].alt : ""}
            />
            {/* </LazyLoad> */}
          </div>
          <div className="catalog-detailed__info">
            <h3 className="catalog-detailed__name">{item ? item.title : ""}</h3>
            <div className="catalog-detailed__cta-row">
              <h4 className="catalog-detailed__pricetag">{`${
                item ? item.price : ""
              } Ft/${item ? item.measure : ""}`}</h4>
              <Button
                buttonText="Szeretném megvásárolni"
                isLink={true}
                isPrimary={false}
                linkPath="/contact"
                clickEvent={() => {}}
              />
            </div>
            <CatalogDetailedArticle
              isListed={false}
              title="Termékleírás"
              content={[item.description]}
            />
            <CatalogDetailedArticle
              isListed={true}
              title="Összetevők"
              content={item.ingredients}
            />
            <CatalogDetailedArticle
              isListed={true}
              title="Szállítási és tárolási javaslat"
              content={item.recommendations}
            />
          </div>
        </>
      )}
    </section>
  );
};
