import { FacebookLink } from "./FacebookLink";
import { FooterLink } from "./FooterLink";
import { Switch, Route } from "react-router-dom";

export const Footer = () => {
  return (
    <Switch>
      <Route path="/catalog">
        <div></div>
      </Route>
      <Route path="/">
        <footer className="footer">
          <nav className="footer__navgroups">
            <section className="footer__navgroup">
              <header className="footer__group-header">
                <div className="footer__group-rect"></div>
                <h4 className="footer__group-title">Ismerj meg</h4>
              </header>
              <div className="footer__group-list">
                <FooterLink path="/" text="Főoldal" />

                <FooterLink path="/about" text="Rólunk" />
              </div>
            </section>
            <section className="footer__navgroup">
              <header className="footer__group-header">
                <div className="footer__group-rect"></div>
                <h4 className="footer__group-title">Válogass</h4>
              </header>
              <ul className="footer__group-list">
                <li>
                  <FooterLink path="/catalog" text="Katalógus" />
                </li>
              </ul>
            </section>
            <section className="footer__navgroup">
              <header className="footer__group-header">
                <div className="footer__group-rect"></div>
                <h4 className="footer__group-title">Üzenj</h4>
              </header>
              <ul className="footer__group-list">
                <li>
                  <FooterLink path="/contact" text="Kapcsolat" />
                </li>
              </ul>
            </section>
            <section className="footer__navgroup">
              <header className="footer__group-header">
                <div className="footer__group-rect"></div>
                <h4 className="footer__group-title">Kövess</h4>
              </header>
              <ul className="footer__group-list">
                <li>
                  <FacebookLink />
                </li>
              </ul>
            </section>
          </nav>
          <h5 className="footer__trademark">2021 - MISZTERHÚS ©</h5>
        </footer>
      </Route>
    </Switch>
  );
};
