import { FC } from "react";

interface IProps {
  item: CatalogItem;
  setIsOpen: (open: boolean) => void;
}

export const FullPictureOverlay: FC<IProps> = ({ item, setIsOpen }: IProps) => {
  return (
    <section className="picture-overlay">
      <header className="picture-overlay__headline">
        <div className="picture-overlay__headline-content">
          <h3 className="picture-overlay__item-name">
            {item ? item.title : ""}
          </h3>
          <svg
            onClick={() => setIsOpen(false)}
            className="picture-overlay__close-svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                className="picture-overlay__close-fill"
                d="M17.7674 16.001L31.6334 2.13493C32.1216 1.64674 32.1216 0.855241 31.6334 0.367116C31.1452 -0.121008 30.3537 -0.12107 29.8656 0.367116L15.9995 14.2332L2.13346 0.367116C1.64527 -0.12107 0.853776 -0.12107 0.365652 0.367116C-0.122472 0.855303 -0.122535 1.6468 0.365652 2.13493L14.2317 16.001L0.365652 29.8671C-0.122535 30.3552 -0.122535 31.1467 0.365652 31.6349C0.609714 31.8789 0.929651 32.0009 1.24959 32.0009C1.56952 32.0009 1.8894 31.8789 2.13352 31.6349L15.9995 17.7688L29.8655 31.6349C30.1096 31.8789 30.4295 32.0009 30.7495 32.0009C31.0694 32.0009 31.3893 31.8789 31.6334 31.6349C32.1216 31.1467 32.1216 30.3552 31.6334 29.8671L17.7674 16.001Z"
                fill="#DA071F"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </header>
      <div className="picture-overlay__content">
        <div className="picture-overlay__image-container">
          <img
            className="picture-overlay__image"
            src={item ? item.pictures[0].url : ""}
            alt={item ? item.pictures[0].alt : ""}
          />
        </div>
      </div>
    </section>
  );
};
