export const Privacy = () => {
  return (
    <main className="privacy">
      <h1 className="privacy__header--one">
        ADATKEZELÉSI TÁJÉKOZTATÓ SZEMÉLYES ADATKEZELÉSHEZ KAPCSOLÓDÓAN
      </h1>
      <h2 className="privacy__header--two">
        1. Az adatkezelő neve és elérhetősége
      </h2>
      <h3 className="privacy__header--three ">
        1.1.A jelen adatkezelés vonatkozásában az
      </h3>
      <p className="privacy__paragraph">
        Adatkezelő neve: Miszterhús Békéssámson Kft.
      </p>
      <p className="privacy__paragraph">
        Székhelye: Békéssámson, Lehel u. 2, 5946
      </p>
      <p className="privacy__paragraph">Email címe: miszterhus@gmail.com</p>
      <p className="privacy__paragraph">
        mint adatkezelő, (a továbbiakban, mint Adatkezelő), kezeli az Ön
        személyes adatait. Ön, mint az adatkezelés kapcsán azonosított vagy
        azonosítható természetes személy, az adatkezelés érintettje lesz.
      </p>
      <h3 className="privacy__header--three ">
        1.2.Az Adatkezelő határozza meg a
      </h3>
      <p className="privacy__paragraph">
        személyes adatok kezelésének céljait és eszközeit. Adatkezelés alatt, az
        alábbi műveletek értendőek: a személyes adatokon vagy adatállományokon
        automatizált vagy nem automatizált módon végzett bármely művelet vagy
        műveletek összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás,
        tárolás, átalakítás vagy megváltoztatás, lekérdezés, betekintés,
        felhasználás, közlés továbbítás, terjesztés vagy egyéb módon történő
        hozzáférhetővé tétel útján, összehangolás vagy összekapcsolás,
        korlátozás, törlés, illetve megsemmisítés.
      </p>
      <h2 className="privacy__header--two">
        2. Az adatkezelés célja és időtartama
      </h2>
      <h3 className="privacy__header--three ">
        2.1.Az adatkezelés céljának leírása:
      </h3>
      <p className="privacy__paragraph">
        a) az Adatkezelő tevékenységével összefüggő kapcsolattartás
      </p>
      <p className="privacy__paragraph">
        b) értesítés és tájékoztatás (hírlevelek stb.) az Adatkezelő
        tevékenységével összefüggésben
      </p>
      <p className="privacy__paragraph">
        c) nyilvántartási adatok (név és örökbefogadási adatok, illetve hely/fa
        összekötése) és ennek nyilvános (honlap és telefonos applikáció)
        közzététele
      </p>
      <h3 className="privacy__header--three ">
        2.2.Az adatkezelés időtartama: A jelen hozzájárulás visszavonásáig.
      </h3>
      <h2 className="privacy__header--two">
        3. Az adatkezeléssel érintett adatok köre, az adatkezelés jogalapja
      </h2>
      <h3 className="privacy__header--three ">
        3.1.A jelen adatkezelés során Önről az alábbi személyes adatokat kezeli
        az Adatkezelő:
      </h3>
      <p className="privacy__paragraph">e-mail cím</p>
      <h3 className="privacy__header--three ">
        3.2.Az Adatkezelés jogalapja az
      </h3>
      <p className="privacy__paragraph">
        Ön konkrét, önkéntes - jelen tájékoztatásban foglalt feltételek alapján
        történő - tájékozott és kifejezett hozzájárulása. [az információs
        önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII.
        törvény, valamint az Európai Parlament és Tanács (EU) 2016/679. számú -
        természetes személyeknek a személyes adatok kezelése tekintetében
        történő védelméről és az ilyen adatok szabad áramlásáról valamint a
        95/46/EK irányelv hatályon kívül helyezéséről szóló - rendeletének
        (általános adatvédelmi rendelet, azaz az ún. GDPR) vonatkozó
        rendelkezései alapján.]
      </p>
      <h2 className="privacy__header--two">
        4. Önt, mint az adatkezelés érintettjét megillető jogok
      </h2>
      <h3 className="privacy__header--three ">
        4.1.Önt, mint az adatkezelés érintettjét, az alábbi jogok illetik meg az
        Önről kezelt személyes adatok vonatkozásában:
      </h3>
      <p className="privacy__paragraph">
        4.1.1. kérelmezheti az Adatkezelőtől az Önről kezelt személyes adatokhoz
        történő hozzáférést; [A hozzáférés joga lehetőséget biztosít Önnek arra,
        hogy visszajelzést kapjon az Adatkezelőtől arra vonatkozóan, hogy
        folyamatban van-e Önre vonatkozóan adatkezelés, amennyiben igen, úgy
        pedig jogosult hozzáférni a személyes adataihoz valamint tájékoztatást
        kérni az Adatkezelőtől az adatkezelést érintő információkról.]
      </p>
      <p className="privacy__paragraph">
        4.1.2. a személyes adatok helyesbítését illetve törlését, valamint ezen
        adatok kezelésének korlátozását jogosult kérelmezni; [A helyesbítés és
        törlés joga („elfeledtetéshez való”jog) lehetőséget biztosít arra, hogy
        pontatlan vagy hiányos adatok esetén azok helyesbítését kérje az
        adatkezelőtől, illetve hogy személyes adatainak törlését igényelje. Ha
        Ön a személyes adatok kezelésének korlátozását kéri, akkor az Adatkezelő
        az adatkezelést az Ön kérelmének megfelelően korlátozhatja. Ha Ön
        vitatja a személyes adatainak pontosságát, akkor a korlátozás időtartama
        arra az időtartamra vonatkozik, amely lehetővé teszi az Adatkezelő
        részére ezen adatok pontosságának ellenőrzését. Ön kérheti a személyes
        adatok felhasználásának korlátozását, ha az adatkezelés jogellenes, de
        Ön ellenzi azok törlését. Ön előterjeszthet ilyen kérelmet akkor is, ha
        az adatkezelőnek már nincs szükséges a személyes adataira adatkezelés
        céljából, de Ön igényli az adatkezelés korlátozását, jogi igények
        előterjesztéséhez, érvényesítéséhez vagy védelméhez]
      </p>
      <p className="privacy__paragraph">
        4.1.3. megilleti az adatainak hordozhatóságához való jog; [Az
        adathordozhatósághoz való jog keretében Ön jogosult arra, hogy az
        Adatkezelő rendelkezésére bocsátott személyes adatait tagolt, széles
        körben használt, géppel olvasható formátumban megkapja, továbbá jogosult
        arra, hogy ezeket az adatokat egy másik adatkezelőnek továbbítsa
        anélkül, hogy ezt akadályozná az Adatkezelő. Ha technikailag
        megvalósítható, akkor Ön azt is kérheti, hogy a személyes adatait az
        Adatkezelő más adatkezelő részére közvetlenül továbbítsa.]
      </p>
      <p className="privacy__paragraph">
        4.1.4. megilleti azon jog, hogy az adatkezeléshez adott hozzájárulását
        részben vagy egészben bármikor visszavonja. [Felhívjuk a figyelmét arra,
        hogy a hozzájárulás visszavonása nem érinti a visszavonás előtt az Ön
        hozzájárulás alapján végrehajtott adatkezelés jogszerűségét]
      </p>
      <h3 className="privacy__header--three ">
        4.2.A fenti 4.1. pontban rögzített jogosultságokat
      </h3>
      <p className="privacy__paragraph">
        Ön, mint az adatkezelés érintettje, a hatályos magyar [így különösen az
        az információs önrendelkezési jogról és az információszabadságról szóló
        2011. évi CXII. törvény] valamint az európai uniós adatvédelmi
        jogszabályokban foglaltak, [így különösen az Európai parlament és tanács
        (EU) 2016/679. számú. a természetes személyeknek a személyes adatok
        kezelése tekintetében történő védelméről és az ilyen adatok szabad
        áramlásáról valamint a 95/46/EK irányelv hatályon kívül helyezéséről
        szóló rendeletének vonatkozó rendelkezései (általános adatvédelmi
        rendelet)] alapján gyakorolhatja, írásban, az Adatkezelő részére írt,
        jelen tájékoztató 1. fejezetben megjelölt székhelyére küldött postai
        küldemény útján, vagy elektronikus levelezési címére küldött e-mail
        útján.
      </p>
      <h2 className="privacy__header--two">
        5. Panasz benyújtás joga, Bírósági jogérvényesítés, kártérítés
        jogellenes adatkezeléssel kapcsolatban
      </h2>
      <h3 className="privacy__header--three ">
        5.1.Amennyiben Ön a személyes adatainak kezelése adatkezelés során a
        jogainak megsértését észleli, az alábbi lehetőségek állnak
        rendelkezésére:
      </h3>
      <p className="privacy__paragraph">
        • közvetlenül az Adatkezelőhöz fordulhat postai levélben vagy emailen
        keresztül az I. fejezetben rögzített elérhetőségeken.
      </p>
      <p className="privacy__paragraph">
        • az érintett felügyeleti hatóságnál, a Nemzeti Adatvédelmi és
        Információszabadság Hatóságnál [NAIH] panaszt nyújthat be. A NAIH
        elérhetőségei: Székhely: H-1125 Budapest, Szilágyi Erzsébet fasor 22/c.
        Telefon: 06-1-391-1400 Email: ugyfelszolgalat@naih.hu
      </p>
      <p className="privacy__paragraph">
        • bírósághoz fordulhat adatai jogellenes kezelése, valamint az
        adatbiztonság követelményeinek megszegése esetén. A jogszabályban
        rögzítettek szerint jogosult lehet kártérítésre, valamint sérelem díjra.
        A bíróság illetékességéről és elérhetőségeiről az alábbi honlapon
        tájékozódhat: www.birosagok.hu
      </p>
      <h2 className="privacy__header--two">
        6. Az adatkezeléssel kapcsolatos nyilatkozatok közlése valamint az
        adatszolgáltatási kötelezettség
      </h2>
      <h3 className="privacy__header--three ">
        6.1.Az adatkezeléssel illetve a jelen tájékoztatóban foglaltakkal
        kapcsolatos
      </h3>
      <p className="privacy__paragraph">
        kérelmeit, nyilatkozatait, észrevételeit és esetleges kérdéseit az
        Adatkezelő 1. pontban megjelölt székhelyre továbbított postai
        küldeményútján, vagy Adatkezelő következő elektronikus levelezési címére
        küldött e-mail útján teheti meg.
      </p>
    </main>
  );
};
