import { CatalogList } from "../components/catalog/CatalogList";
import { FC, useEffect } from "react";
import { CatalogDetailedItem } from "../components/catalog/CatalogDetailedItem";
// import catalog from "../utils/catalog.json";
import catalog from "../utils/catalog-generated.json";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { cacheImages } from "../utils/preload";
// import { useStorage } from "../utils/history";
//Framer-Motion
// import { pageAnimation } from "../utils/animations";
// import { motion } from "framer-motion";

export const Catalog: FC = () => {
  let { id } = useParams<{ id: string }>();
  let history = useHistory();
  // const shouldRefresh = useStorage(true);

  useEffect(() => {
    cacheImages(catalog.map((item) => item.pictures[0].url));
  }, []);

  useEffect(() => {
    localStorage.setItem("lastId", id);
  }, [id]);

  const setActiveItem = (id: number) => {
    if (id !== 0 && id <= catalog.length) history.replace(id.toString());
  };

  if (!catalog.map((item) => item.id).includes(parseInt(id)))
    return <Redirect to="/404" />;
  else
    return (
      <>
        <main className="catalog-page">
          <CatalogList
            isMobile={false}
            catalog={catalog}
            active={parseInt(id)}
            setActiveItem={setActiveItem}
          />
          <CatalogDetailedItem
            catalog={catalog}
            active={parseInt(id)}
            item={catalog[parseInt(id) - 1]}
            setActiveItem={setActiveItem}
          />
        </main>
      </>
    );
};
