import React, { FC, useState } from "react";
import { CatalogListItem } from "./CatalogListItem";
import { getCategories } from "../../utils/catalog";
import Select from "react-select";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
// import { pageAnimation } from "../../utils/animations";
// import { motion } from "framer-motion";

interface IProps {
  isMobile: boolean;
  setIsOpen?: (open: boolean) => void;
  catalog: CatalogItem[];
  active: number;
  setActiveItem: (active: number) => void;
}

export const CatalogList: FC<IProps> = ({
  isMobile,
  setIsOpen = () => {},
  catalog,
  active,
  setActiveItem,
}: IProps) => {
  const cache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 160,
    })
  );
  const cache_mobile = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 160,
    })
  );
  const [categories] = useState<{ label: string; value: number }[]>(
    getCategories(catalog).map((category, i) => ({
      label: category,
      value: i,
    }))
  );
  const [selected, setSelected] = useState<number>(0);
  const list = catalog
    ? catalog.filter(
        (item) =>
          selected === 0 || item.categories.includes(categories[selected].label)
      )
    : [];

  return (
    <>
      {isMobile ? (
        <section className="catalog-list--mobile">
          <style
            dangerouslySetInnerHTML={{
              __html: `
  body { overflow-y: hidden }
`,
            }}
          />
          <div className="catalog-list--mobile__contents">
            <div className="catalog-list--mobile__top">
              <div className="catalog-list--mobile__header">
                <h1 className="catalog-list--mobile__title">Katalógus</h1>
                <svg
                  onClick={() => setIsOpen(false)}
                  className="catalog-list--mobile__close-svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      className="catalog-list--mobile__close-fill"
                      d="M17.7674 16.001L31.6334 2.13493C32.1216 1.64674 32.1216 0.855241 31.6334 0.367116C31.1452 -0.121008 30.3537 -0.12107 29.8656 0.367116L15.9995 14.2332L2.13346 0.367116C1.64527 -0.12107 0.853776 -0.12107 0.365652 0.367116C-0.122472 0.855303 -0.122535 1.6468 0.365652 2.13493L14.2317 16.001L0.365652 29.8671C-0.122535 30.3552 -0.122535 31.1467 0.365652 31.6349C0.609714 31.8789 0.929651 32.0009 1.24959 32.0009C1.56952 32.0009 1.8894 31.8789 2.13352 31.6349L15.9995 17.7688L29.8655 31.6349C30.1096 31.8789 30.4295 32.0009 30.7495 32.0009C31.0694 32.0009 31.3893 31.8789 31.6334 31.6349C32.1216 31.1467 32.1216 30.3552 31.6334 29.8671L17.7674 16.001Z"
                      fill="#DA071F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <label htmlFor="react-select-2-input" style={{ opacity: 0 }}>
                Select category
              </label>
              <Select
                name="catalog-selector"
                className={"catalog-selector"}
                classNamePrefix={"catalog-selector"}
                noOptionsMessage={() => "Nincs találat"}
                defaultValue={categories[0]}
                options={categories}
                onChange={(value: any) =>
                  value ? setSelected(value.value) : null
                }
              />
            </div>
            <div className="catalog-list--mobile__container">
              <AutoSizer
                onResize={() => {
                  cache_mobile.current.clearAll();
                }}
              >
                {({ height, width }) => {
                  return (
                    <List
                      width={width}
                      height={height}
                      rowHeight={cache_mobile.current.rowHeight}
                      rowCount={list.length}
                      scrollToIndex={active}
                      deferredMeasurementCache={cache_mobile.current}
                      rowRenderer={({ key, index, parent, style }) => {
                        const item = list[index];
                        return (
                          <CellMeasurer
                            key={key}
                            cache={cache_mobile.current}
                            columnIndex={0}
                            rowIndex={index}
                            parent={parent}
                          >
                            <div
                              onClick={() => setActiveItem(item.id)}
                              style={style}
                            >
                              <CatalogListItem
                                item={item}
                                active={active === index + 1}
                              />
                            </div>
                          </CellMeasurer>
                        );
                      }}
                    />
                  );
                }}
              </AutoSizer>
            </div>
          </div>
        </section>
      ) : (
        <section className="catalog-list">
          <div className="catalog-list__top">
            <h1 className="catalog-list__title">Katalógus</h1>
            {/* TODO */}
            <label htmlFor="react-select-2-input" style={{ opacity: 0 }}>
              Select category
            </label>
            <Select
              className={"catalog-selector"}
              classNamePrefix={"catalog-selector"}
              noOptionsMessage={() => "Nincs találat"}
              defaultValue={categories[0]}
              options={categories}
              onChange={(value) => (value ? setSelected(value.value) : null)}
            />
          </div>
          <div className="catalog-list__container">
            <AutoSizer
              onResize={() => {
                cache.current.clearAll();
              }}
            >
              {({ height, width }) => {
                return (
                  <List
                    width={width}
                    height={height}
                    rowHeight={cache.current.rowHeight}
                    rowCount={list.length}
                    deferredMeasurementCache={cache.current}
                    scrollToIndex={active}
                    rowRenderer={({ key, index, parent, style }) => {
                      const item = list[index];

                      return (
                        <CellMeasurer
                          key={key}
                          cache={cache.current}
                          columnIndex={0}
                          rowIndex={index}
                          parent={parent}
                        >
                          <div
                            onClick={() => setActiveItem(item.id)}
                            style={style}
                          >
                            <CatalogListItem
                              item={item}
                              active={active === index + 1}
                            />
                          </div>
                        </CellMeasurer>
                      );
                    }}
                  />
                );
              }}
            </AutoSizer>
          </div>
        </section>
      )}
    </>
  );
};
