export const pageAnimation = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.25,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.0,
      },
    },
  };

  export const secondItemAnimation = {
    hidden: {
      opacity: 0,
      y: 400
    },
    show: {
      opacity: 1,
      y:0,
      transition: {
        duration: 0.9
      },
    }
  };

  export const indexPicAnim = {
    init: {
      y: -300,
      x: 600,
      scale: 1.1
    },
    final: {
      y:0,
      x:0,
      scale: 1,
      transition: {
        duration: 0.9,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    exit: {
      opacity: 0,
      x: 400,
      transition: {
        duration: 0.0,
      },
    },
  };

  export const aboutPicAnim = {
    init: {
      y: -500,
      scale: 1.2
    },
    final: {
      y:0,
      scale: 1,
      transition: {
        duration: 0.9,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    exit: {
      opacity: 0,
      x: 400,
      transition: {
        duration: 0.0,
      },
    },
  };

  export const contactPicAnim = {
    init: {
      x: 600,
    },
    final: {
      x:0,
      transition: {
        duration: 0.9,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    exit: {
      opacity: 0,
      x: 400,
      transition: {
        duration: 0.0,
      },
    },
  };

  export const contactArticleAnim = {
    init: {
      x: -600,
      y: - 200,
    },
    final: {
      y:0,
      x:0,
      scale: 1,
      transition: {
        duration: 0.9,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    exit: {
      opacity: 0,
      x: 400,
      transition: {
        duration: 0.0,
      },
    },
  };

  export const indexArticleAnim = {
    init: {
      x: -600,
    },
    final: {
      x:0,
      transition: {
        duration: 0.9,
        when: "beforeChildren",
        staggerChildren: 0.25,
      },
    },
    exit: {
      opacity: 0,
      x: 400,
      transition: {
        duration: 0.0,
      },
    },
  };

  export const aboutArticleAnim = {
    init: {
      x: -700,
    },
    final: {
      x:0,
      transition: {
        duration: 0.9,
        when: "beforeChildren",
        staggerChildren: 0.25,
      },
    },
    exit: {
      opacity: 0,
      x: 400,
      transition: {
        duration: 0.0,
      },
    },
  };

  export const scrollReveal = {
    hidden: { opacity: 0, scale: 0.9, transition: { duration: 0.3 } },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
      },
    },
  };