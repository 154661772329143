import { FC } from "react";
// import LazyLoad from "react-lazyload";

interface IProps {
  item: CatalogItem;
  active: boolean;
}

export const CatalogListItem: FC<IProps> = ({ item, active }: IProps) => {
  return (
    <div className={["catalog-list-item", active ? "active" : null].join(" ")}>
      <div className="catalog-list-item__picture-container">
        {active ? <div className="catalog-list-item__strip" /> : null}
        {/* <LazyLoad once height={800}> */}
        <img
          // src={window.location.origin + "/" + item.pictures[0].url}
          className="catalog-list-item__picture"
          src={item ? item.pictures[0].url : ""}
          alt={item ? item.pictures[0].alt : ""}
        />
        {/* </LazyLoad> */}
      </div>
      <div className="catalog-list-item__info">
        <h2 className="catalog-list-item__title">{item ? item.title : ""}</h2>
        <span className="catalog-list-item__price-tag">{`${
          item ? item.price : ""
        } Ft/${item ? item.measure : ""}`}</span>
      </div>
    </div>
  );
};
