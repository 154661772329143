import React, { FC } from "react";
//Framer-Motion
import { pageAnimation } from "../utils/animations";
import { motion } from "framer-motion";

export const NotFound: FC = () => {
  return (
    <motion.main
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      className="not-found-page"
      style={{ position: "relative" }}
    >
      <h3 className="not-found-page__text">Az oldal nem található.</h3>
    </motion.main>
  );
};
