import { Link } from "react-router-dom";
import { FC } from "react";

interface IProps {
  path: string;
  text: string;
}

export const HeaderLink: FC<IProps> = ({ path, text }: IProps) => {
  return (
    <Link className="header-link" to={path}>
      <p className="header-link__text">{text}</p>
      <div className="header-link__underline"></div>
    </Link>
  );
};
