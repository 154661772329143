import { FC } from "react";

interface IProps {
  isLeft: boolean;
  arrowText: string;
  clickEvent: () => void;
}

export const CatalogArrow: FC<IProps> = ({
  isLeft,
  arrowText,
  clickEvent,
}: IProps) => {
  return (
    <button onClick={clickEvent} className="catalog-arrow">
      {isLeft ? (
        <div className="catalog-arrow__body--default catalog-arrow__body--left">
          <div className="catalog-arrow__line"></div>
          <div className="catalog-arrow__inner-body">
            <svg
              className="catalog-arrow__svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M4.50136 9.32536L10.979 15.8165C11.2229 16.0608 11.6186 16.0612 11.8629 15.8174C12.1072 15.5736 12.1076 15.1779 11.8638 14.9335L5.3857 8.44192C5.14202 8.19823 5.14202 7.80173 5.38617 7.55758L11.8638 1.06645C12.1076 0.822107 12.1072 0.426388 11.8629 0.182575C11.7409 0.0608253 11.5811 -1.72068e-05 11.4214 -1.72208e-05C11.2612 -1.72348e-05 11.1011 0.0611686 10.979 0.183513L4.50183 6.67417C3.77077 7.40523 3.77077 8.59477 4.50136 9.32536Z"
                  fill="#DA071F"
                  className="catalog-arrow__svg-fill"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(16 16) rotate(-180)"
                  />
                </clipPath>
              </defs>
            </svg>
            <p className="catalog-arrow__text--default catalog-arrow__text--left">
              {arrowText}
            </p>
          </div>
          <div className="catalog-arrow__line"></div>
        </div>
      ) : (
        <div className="catalog-arrow__body--default catalog-arrow__body--right">
          <div className="catalog-arrow__line"></div>
          <div className="catalog-arrow__inner-body">
            <p className="catalog-arrow__text--default catalog-arrow__text--right">
              {arrowText}
            </p>
            <svg
              className="catalog-arrow__svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M11.4986 6.67464L5.02102 0.183512C4.77714 -0.0608317 4.38142 -0.0612068 4.13714 0.182606C3.8928 0.426418 3.89239 0.822137 4.1362 1.06648L10.6143 7.55808C10.858 7.80176 10.858 8.19827 10.6138 8.44242L4.1362 14.9335C3.89239 15.1779 3.8928 15.5736 4.13714 15.8174C4.25911 15.9392 4.41889 16 4.57861 16C4.73877 16 4.89892 15.9388 5.02102 15.8165L11.4982 9.32583C12.2292 8.59477 12.2292 7.40523 11.4986 6.67464Z"
                  fill="#DA071F"
                  className="catalog-arrow__svg-fill"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="catalog-arrow__line"></div>
        </div>
      )}
    </button>
  );
};
